import { type Unsubscribe, getAuth, onAuthStateChanged } from 'firebase/auth'
import { Account } from '~/src/account/account'
import { User } from '~/src/account/user'
import { userStore } from '~/stores/user'
import { accountStore } from '~/stores/account'


let onAuthChanged: Unsubscribe

export function initializeMythos() {
  if (onAuthChanged)
    onAuthChanged()

  onAuthChanged = onAuthStateChanged(getAuth(), async (fbUser) => {
    const publicAccessRoutes = ['login', 'register', 'forgot-password', 'reset', 'workflow-workflow_ref', 'threading-thread_ref']
    const redirectIfLoggedInRoutes = ['login', 'register', 'forgot']

    // if the visitor isn't logged in and they aren't in a public page -> redirect
    if (!fbUser && !publicAccessRoutes.includes(useRoute().name?.toString() || ''))
      await navigateTo({ name: 'login' })

    // if the visitor is logged in -> initialize the app & redirect if necessary
    if (fbUser) {
      const user = new User(null, null, null, null)
      const account = new Account(null, null, null, null, null)

      try {
        // authenticate on server
        // initialize user
        await user.initialize(fbUser)

        // initialize account
        await account.initialize()

        const uStore = userStore()
        const aStore = accountStore()
        uStore.$patch({user: user})
        aStore.$patch({account: account})
      }
      catch (error) {
        console.error(error)
        return await navigateTo({ name: 'login' })
      }

      // redirect if an authorized user is in login, forgot password page, etc.
      if (redirectIfLoggedInRoutes.includes(useRoute().name?.toString() || ''))
        return await navigateTo({ name: 'index' })
    }
  })
}
