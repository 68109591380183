import { default as adsv5dw834cRvMeta } from "/usr/src/mythos/pages/admin/ads.vue?macro=true";
import { default as indexnAr5fPWp1cMeta } from "/usr/src/mythos/pages/admin/index.vue?macro=true";
import { default as campaignsFwUBp7wihnMeta } from "/usr/src/mythos/pages/campaigns.vue?macro=true";
import { default as connectZuS7sJoV18Meta } from "/usr/src/mythos/pages/connect.vue?macro=true";
import { default as fileset6IUKVmiGMeta } from "/usr/src/mythos/pages/files.vue?macro=true";
import { default as folderSwfzrbqTK2Meta } from "/usr/src/mythos/pages/folder.vue?macro=true";
import { default as forgot_45password9wyJScjyylMeta } from "/usr/src/mythos/pages/forgot-password.vue?macro=true";
import { default as indextCIjiFZW3SMeta } from "/usr/src/mythos/pages/index.vue?macro=true";
import { default as insightsk11UlkIJ1YMeta } from "/usr/src/mythos/pages/insights.vue?macro=true";
import { default as login1LhtBXNUOcMeta } from "/usr/src/mythos/pages/login.vue?macro=true";
import { default as moderationJqv1eTvlqRMeta } from "/usr/src/mythos/pages/moderation.vue?macro=true";
import { default as registerhVtkb7RKxaMeta } from "/usr/src/mythos/pages/register.vue?macro=true";
import { default as resetv2IThclviTMeta } from "/usr/src/mythos/pages/reset.vue?macro=true";
import { default as settingsaP7m5HegitMeta } from "/usr/src/mythos/pages/settings.vue?macro=true";
import { default as _91thread_ref_93YcLLkex1ttMeta } from "/usr/src/mythos/pages/threading/[thread_ref].vue?macro=true";
import { default as _91workflow_ref_93a2YNvIle6hMeta } from "/usr/src/mythos/pages/workflow/[workflow_ref].vue?macro=true";
import { default as _91workflow_ref_93nzUty6A3Y8Meta } from "/usr/src/mythos/pages/workflows/[workflow_ref].vue?macro=true";
import { default as createGhUk7mF4xsMeta } from "/usr/src/mythos/pages/workflows/create.vue?macro=true";
import { default as indexREe7fAYT2tMeta } from "/usr/src/mythos/pages/workflows/index.vue?macro=true";
export default [
  {
    name: "admin-ads",
    path: "/admin/ads",
    component: () => import("/usr/src/mythos/pages/admin/ads.vue")
  },
  {
    name: "admin",
    path: "/admin",
    component: () => import("/usr/src/mythos/pages/admin/index.vue")
  },
  {
    name: "campaigns",
    path: "/campaigns",
    component: () => import("/usr/src/mythos/pages/campaigns.vue")
  },
  {
    name: "connect",
    path: "/connect",
    component: () => import("/usr/src/mythos/pages/connect.vue")
  },
  {
    name: "files",
    path: "/files",
    component: () => import("/usr/src/mythos/pages/files.vue")
  },
  {
    name: "folder",
    path: "/folder",
    component: () => import("/usr/src/mythos/pages/folder.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    component: () => import("/usr/src/mythos/pages/forgot-password.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/usr/src/mythos/pages/index.vue")
  },
  {
    name: "insights",
    path: "/insights",
    component: () => import("/usr/src/mythos/pages/insights.vue")
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/usr/src/mythos/pages/login.vue")
  },
  {
    name: "moderation",
    path: "/moderation",
    component: () => import("/usr/src/mythos/pages/moderation.vue")
  },
  {
    name: "register",
    path: "/register",
    component: () => import("/usr/src/mythos/pages/register.vue")
  },
  {
    name: "reset",
    path: "/reset",
    component: () => import("/usr/src/mythos/pages/reset.vue")
  },
  {
    name: "settings",
    path: "/settings",
    component: () => import("/usr/src/mythos/pages/settings.vue")
  },
  {
    name: "threading-thread_ref",
    path: "/threading/:thread_ref()",
    component: () => import("/usr/src/mythos/pages/threading/[thread_ref].vue")
  },
  {
    name: "workflow-workflow_ref",
    path: "/workflow/:workflow_ref()",
    component: () => import("/usr/src/mythos/pages/workflow/[workflow_ref].vue")
  },
  {
    name: "workflows-workflow_ref",
    path: "/workflows/:workflow_ref()",
    component: () => import("/usr/src/mythos/pages/workflows/[workflow_ref].vue")
  },
  {
    name: "workflows-create",
    path: "/workflows/create",
    component: () => import("/usr/src/mythos/pages/workflows/create.vue")
  },
  {
    name: "workflows",
    path: "/workflows",
    component: () => import("/usr/src/mythos/pages/workflows/index.vue")
  }
]