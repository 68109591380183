import {
  BarElement,
  BubbleController,
  CategoryScale,
  Chart,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js'
import { WordCloudController, WordElement } from 'chartjs-chart-wordcloud'

export default defineNuxtPlugin(() => {
  Chart.register(
    BubbleController,
    WordCloudController,
    WordElement,
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  )
})
