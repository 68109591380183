import vClickOutside from 'click-outside-vue3'
// import LeaderLine from 'leader-line-vue'
import { PiniaSharedState } from 'pinia-shared-state'
import mitt from 'mitt'
const emitter = mitt()

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(vClickOutside)
  // nuxtApp.vueApp.use(LeaderLine)
  nuxtApp.provide('bus', {
    $on: emitter.on,
    $emit: emitter.emit,
  })
  nuxtApp.$pinia.use(PiniaSharedState({
    enable: true,
  }))
})
