import type { UserCredential } from 'firebase/auth'
import { collection, doc, getFirestore, setDoc } from 'firebase/firestore'
import type { Workflow } from '../workflow/workflow'
import { User } from './user'
import { userStore } from '~/stores/user'
import { accountStore } from '~/stores/account'

export class Account {
  accountRef: string | null
  businessName: string | null
  businessWebsite: string | null
  admin: string[] | null
  people: string[] | null
  workflows: Workflow[]

  constructor(accountRef: string | null, businessName: string | null, businessWebsite: string | null, admin: string[] | null, people: string[] | null) {
    this.accountRef = accountRef
    this.businessName = businessName
    this.businessWebsite = businessWebsite
    this.admin = admin
    this.people = people
    this.workflows = []
  }

  async initialize() {
    const user = userStore().user

    if (!user)
      throw new Error('User object is null. Initialize user before initializing Account.')

    // get accounts from backend; firebase where qry not possible w/o letting people access each others data
    const accountData = await this.fetch()
    if (!accountData) {
      await user.logout()
      throw new Error('No account with this user exists.')
    }
    this.accountRef = accountData.account_ref
    this.businessName = accountData.business_name
    this.businessWebsite = accountData.business_website
    this.admin = accountData.admin
    this.people = accountData.people

    accountStore().account = this
  }

  public async register(firstname: string, lastname: string, email: string, password: string, businessName: string, businessWebsite: string) {
    this.businessName = businessName
    this.businessWebsite = businessWebsite

    const router = useRouter()
    const user = new User(null, null, null, null)

    try {
      const credentials = await user.registerUser(firstname, lastname, email, password)
      if (credentials == null)
        return { success: false, error: "User Account Signup has been disabled."}

      // create firebase doc in "accounts" collection
      await this.setup(credentials, businessName, businessWebsite)

      router.push('/')
    }
    catch (error) {
      console.error(error)
      return { success: false, error }
    }
    return { success: true, error: null }
  }

  async fetch() {
    // make an api call to get the account linked to the logged in user
    const req = await fetch('/api/auth/account', { method: 'GET' })
    const res = await req.json()
    if (res && res.account)
      return res.account
    return null
  }

  async setup(credentials: UserCredential, businessName: string, businessWebsite: string) {
    const db = getFirestore()
    const accDoc = doc(collection(db, 'accounts'))
    setDoc(accDoc, {
      account_ref: accDoc.id,
      admin: [credentials.user.uid],
      people: [credentials.user.uid],
      business_name: businessName,
      business_website: businessWebsite,
    })
  }

  async get_workflows() {

  }
}
