<script setup>
  import { mythosStore } from '~/stores/mythos'

  const mStore = mythosStore()
  const hideMessage = () => mStore.alertMessage = null
</script>

<template>
  <div v-if="mStore.alertMessage" class="absolute left-4 text-center py-4 lg:px-4">
      <div class="p-2 px-3 bg-mythos-violet items-center text-indigo-100 leading-none lg:rounded-xl flex justify-center" role="alert">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="opacity-75 h-8 w-8 p-1 mr-2">
          <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z" />
        </svg>
        <span class="font-semibold mr-2 text-left flex-auto">
          {{ mStore.alertMessage }}
        </span>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6" @click="hideMessage">
          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
        </svg>
      </div>
    </div>
</template>