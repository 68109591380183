import { type User as FirebaseUser, createUserWithEmailAndPassword, getAuth, signInWithEmailAndPassword, updateProfile, sendPasswordResetEmail, confirmPasswordReset } from 'firebase/auth'
import { userStore } from '~/stores/user'

export class User {
  uid: string | null
  email: string | null
  photoURL: string | null
  displayName: string | null
  idToken: string | null

  constructor(uid: string | null, email: string | null, photoURL: string | null, displayName: string | null) {
    this.uid = uid
    this.email = email
    this.photoURL = photoURL
    this.displayName = displayName
    this.idToken = null
  }

  async initialize(fbUser: FirebaseUser) {
    this.uid = fbUser.uid
    this.email = fbUser.email
    this.photoURL = fbUser.photoURL
    this.displayName = fbUser.displayName

    // login to server backend
    this.idToken = await fbUser.getIdToken()

    // initilize store user
    userStore().user = this

    await this.authenticateOnServer(this.idToken)
  }

  async authenticateOnServer(fbIdToken: string) {
    // authenticate user on server backend
    const res = await fetch('/api/auth/login', { method: 'POST', body: JSON.stringify({ fb_id_token: fbIdToken }) })
    const respJson = await res.json()
    if (!respJson || respJson.success === false) {
      // if the server could not validate user, then log them out
      throw new Error('Unable to authenticate the user.')
    }
  }

  public async registerUser(firstname: string, lastname: string, email: string, password: string) {
    const auth = getAuth()
    try {
      const credentials = await createUserWithEmailAndPassword(auth, email, password)
      await updateProfile(credentials.user, { displayName: `${firstname} ${lastname}`, photoURL: 'https://brand-bot.s3.us-east-2.amazonaws.com/public-assets/user.png' })

      this.uid = credentials.user.uid || ''
      this.email = credentials.user.email || ''
      this.photoURL = credentials.user.photoURL || ''
      this.displayName = credentials.user.displayName || ''

      credentials.user.getIdToken(true).then(idToken => this.idToken = idToken)

      userStore().user = this
      return credentials
    }
    catch (error) {
      console.error(error)
      return null
    }
  }

  public async login(email: string, password: string) {
    const auth = getAuth()
    try {
      const credentials = await signInWithEmailAndPassword(auth, email, password)

      this.uid = credentials.user.uid || ''
      this.email = credentials.user.email || ''
      this.photoURL = credentials.user.photoURL || ''
      this.displayName = credentials.user.displayName || ''

      credentials.user.getIdToken(true).then(idToken => this.idToken = idToken)

      userStore().user = this
    }
    catch (error) {
      console.error(error)
      return { success: false, error }
    }
    return { success: true, error: null }
  }

  async forgotPassword(email: string) {
    const auth = getAuth()
    try {
      await sendPasswordResetEmail(auth, email)
    }
    catch (error) {
      console.error(error)
      return { success: false, error }
    }
    return { success: true, error: null }
  }

  async updatePassword(code: string, password: string) {
    const auth = getAuth()
    try {
      await confirmPasswordReset(auth, code, password)
    }
    catch (error) {
      console.error(error)
      return { success: false, error }
    }
    return { success: true, error: null }
  }

  async logout() {
    userStore().user = null
    await getAuth().signOut()
    fetch('/api/auth/logout', { method: 'POST' })
    await navigateTo({ name: 'login' })
  }
}
