import { defineStore } from 'pinia'

export const mythosStore = defineStore('users', {
  state: () => ({
    alertMessage: <string | null> null,
    integrationInitiatedFor: <string | null> null,
    awaitRedditKey: false,
  }),
  actions: {
    create_alert(msg: string) {
      this.alertMessage = msg
      setTimeout(() => {
        this.alertMessage = null
      }, 4000)
    }
  }
})
